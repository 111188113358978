
#home {
    background-color: #161616;
    height: 85vh;
    margin: 0 auto;
    max-width: 1000px;
}

.home-text-cont {
    padding: 10em 0 0 1em;
    text-transform: uppercase;
}

.occupation-text{
    color: #373737;
    letter-spacing: .1rem;
}

.bold-text {
    font-weight: 500;
}


