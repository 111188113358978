#skills{
    margin-top: 5em;   
}

#skillSection {
    background-color: #161616;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    
}

.skillSet {
    font-size: 3.3rem;
}

.sec {
   display: flex;
   justify-content: space-evenly;
   width: 350px;
   height: 150px;
}


.headings {
    font-size: 1.1rem;
    padding: 50px;
    text-align: center;
    text-transform: uppercase;
}

.fcgrey {
    color: #373737;
}

a:link, a:visited{
    text-decoration: none;
    color: white;
  }
  
  .sec > a:active {
    transform: translateY(4px);
  }


  @media (min-width: 700px) {
    
    .skillSet {
        font-size: 5rem;
    }
    
    .sec {
       width: 700px;
       height: 200px;
    }
    
}

@media (min-width: 1024px) {
    
    .skillSet {
        font-size: 6rem;
    }
    
    .sec {
       width: 1000px;
       height: 200px;
    }

}