#footer {
    padding: 40px;
    text-align: center;
    height: 5vh;
}

#year {
    color: #373737;
}


