
#nav {     
    display: flex;
    padding: 5em 0 0 .25em;
    cursor: pointer;
    max-width: 1000px;
    margin: 0 auto;
}



.nav-link:hover {
    color: #E54D26;
}
    

