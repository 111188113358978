#projects {
    background-color: #161616;
    max-width: 1000px;
    margin: 0 auto;
}

.description {
    height: 8em;
    margin-bottom: 1em;
    text-align: left;
}

.technologies {
    margin-top: 1em;
}

.projectPic {
    border-radius: 5px;
    width: 225px;
    height: 250px;
} 


.live-site {
  color: #61DBFB;
  font-weight: 700;
}


.colorizer {
    color: #F0DB4F;
}

.ns {
    color: #E54D26;

}

.techs > span {
    color: rgb(172, 172, 172);
    padding: 1em .10em;
    display: inline-block;
}


