#scroll-up {
    position: fixed; 
    z-index: 1000;
    cursor: pointer;
    bottom: 2.5em;
    right: .50em;
    background-color: #61DBFB;
    width: 1.4em;
    height: 1.4em;
}
