#about {
    background-color: #161616;
    max-width: 1000px;
    margin: 0 auto;
}


.aboutContainer {
    padding: 25px;
}

.connect {
    padding-top: 50px;
    text-align: center;
}

#resume-container {
    padding-bottom: 20px;
    cursor: pointer;
    display: block;
}

#resume {
    border: 1px solid white;
    border-radius: 5px;
    background-color: #83CD29;
    padding: 5px 15px;
    color: white;    
}

#resume:active {
    box-shadow: 0 3px white;
    transform: translateY(3px);
}


